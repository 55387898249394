import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import PageFooter from '../components/sectionFooter';
import Seo from '../components/seo';
import ModularSectionCompany from '../modularSections/companies';
import ModularSectionContent from '../modularSections/content';
import ModularSectionCta from '../modularSections/cta';
import ModularSectionFaq from '../modularSections/faq';
import ModularSectionHeader from '../modularSections/header';
import ModularSectionMap from '../modularSections/map';
import ModularSectionPricing from '../modularSections/pricing';
import ModularSectionStory from '../modularSections/story';

export default ({ data, ...rest }) => {
  const { title, url, sections } = data.datoCmsPage

  return (
    <Layout>
      <Seo {...data.datoCmsPage.seo} pathname={rest.location.pathname} />

      {sections.map((s, key) => {
        if (s.__typename === 'DatoCmsStoriesSection')
          return <ModularSectionStory key={key} {...s} />
        else if (s.__typename === 'DatoCmsFaqSection')
          return <ModularSectionFaq key={key} {...s} />
        else if (s.__typename === 'DatoCmsCompanyLogoListing')
          return <ModularSectionCompany key={key} {...s} />
        else if (s.__typename === 'DatoCmsCallToActionSection')
          return <ModularSectionCta key={key} {...s} />
        else if (s.__typename === 'DatoCmsMapSection')
          return <ModularSectionMap key={key} {...s} />
        else if (s.__typename === 'DatoCmsContentSection')
          return <ModularSectionContent key={key} {...s} />
        else if (s.__typename === 'DatoCmsPricingHeader')
          return <ModularSectionPricing key={key} {...s} url={url} />
        else if (s.__typename === 'DatoCmsHeaderCover')
          return (
            <ModularSectionHeader key={key} {...s} title={title} url={url} />
          )

        return (
          <div>
            {s.__typename} - This section is not supported yet. Please contact
            your developer.
          </div>
        )
      })}

      <PageFooter />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      title
      url
      seo {
        title
        description
        image {
          format
          size
          width
          height
          url
        }
      }

      sections {
        ... on DatoCmsHeaderCover {
          text
          image {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }

        ... on DatoCmsPricingHeader {
          id
          title
          description
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }

        ... on DatoCmsContentSection {
          text
        }

        ... on DatoCmsFaqSection {
          title
          faq {
            id
            question
            answer
          }
          button {
            text
            link
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }

        ... on DatoCmsStoriesSection {
          title
          stories {
            id
            story
            gymName
            personNameJobTitle
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            title
            url
          }
        }

        ... on DatoCmsCompanyLogoListing {
          darkBackgroundColor
          company {
            id
            companyName
            link
            logo {
              fixed(width: 300, height: 80) {
                ...GatsbyDatoCmsFixed
              }
              url
              format
            }
          }
        }

        ... on DatoCmsCallToActionSection {
          id
          cta {
            originalId
            title
            note
            buttonText
            buttonLink
            justifyContent
            isWhiteCta
            isLarge
            background {
              fluid(maxWidth: 2560) {
                ...GatsbyDatoCmsFluid
              }
              alt
              title
              url
            }
          }
        }
      }
    }
  }
`
